<script context="module">
  import PageBody from "$lib/components/Page/PageBody.svelte";
  import isPreviewBuild from "$lib/utilities/isPreviewBuild";
</script>

<script>
  import { setContext } from "svelte";

  import { Button } from "@ObamaFoundation/of-design-system";

  import Seo from "$lib/components/SEO.svelte";
  export let data;

  const isPreview = isPreviewBuild();
  const spaceId = import.meta.env.VITE_CONTENTFUL_SPACE_ID;
  const environmentId = data.pageEntry.sys.environment.sys.id;
  const entryId = data.pageEntry.sys.id;
  const previewEntryURL = `https://app.contentful.com/spaces/${spaceId}/environments/${environmentId}/entries/${entryId}`;

  setContext("pageData", data.pageEntry.fields.pageData);

  const trailingBody = data.pageEntry.fields.trailingBody;
</script>

<Seo
  data={data.pageEntry.fields.pageData.fields}
  currentUrl={data.currentUrl}
  cookieBanner={data.cookieBanner}
/>

<PageBody
  body={data.pageEntry.fields.body}
  class="page-body {trailingBody ? '' : 'last-page-element'}"
/>

{#if trailingBody}
  <PageBody body={trailingBody} class="page-body last-page-element" />
{/if}

{#if isPreview}
  <div class="fixed bottom-8 right-8 z-[--stack-level-layer-top]">
    <Button variant="filled" url={previewEntryURL} label="Edit Page" buttonClass="hover:bg-white" />
  </div>
{/if}
